/* eslint-disable import/no-cycle */
import Home from '../pages/Home'

const routes = [
  {
    path: '/', exact: true, name: 'Home', component: Home,
  },
]

export default routes
