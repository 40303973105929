import { BrowserRouter, Route, Switch } from 'react-router-dom'
import DefaultLayout from './pages/DefaultLayout'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" name="Default Layout" component={DefaultLayout} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
