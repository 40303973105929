/* eslint-disable no-unused-vars */
import React, {
  useEffect, useMemo, useRef, useState, useCallback,
} from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import './index.scss'

export default function Home() {
  return (
    <div className="container">
      <Helmet>
        <title>Thông tin tuyển dụng Công ty Cổ phần Line Century Toàn cầu</title>
        <meta name="description" content="Line Century là Công ty được thành lập bởi các thành viên có nhiều kinh nghiệm triển khai các dự án cung cấp các giải pháp về tài chính và giáo dục cho thị trường quốc tế trên nền tảng Internet." />
      </Helmet>
      <div className="d-flex justify-content-center">
        <div className="text-about">Chúng tôi xây dựng websites và ứng dụng di động nhằm cung cấp dữ liệu và kiến thức về lĩnh vực chứng khoán và cryptocurrency.</div>
      </div>
      <div className="store-cont">
        <a href="https://apps.apple.com/app/apple-store/id1455029448" target="_blank" rel="noreferrer">
          <img className="store-icon" src="/images/appstore.png" alt="app store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=stocks.nasdaq.stock.market.tracker.screener" target="_blank" rel="noreferrer">
          <img className="store-icon" src="/images/googleplay.png" alt="google play" />
        </a>
        <a href="https://stockscan.io/" target="_blank" rel="noreferrer">
          <img className="store-icon" src="/images/stockscan.png" alt="BitScreener" style={{ marginRight: 0 }} />
        </a>
      </div>
      <div className="text-career">
        <span>Tuyển dụng</span>
      </div>
      <div className="career-item">
        <div className="career-item-card">
          <div className="d-flex">
            <div className="left-icon">
              <img src="/images/copy-writer.png" alt="copy-writer" className="career-icon" />
            </div>
            <div>
              <h3 className="career-title">Content Writer - Copy Writer</h3>
              <ul>
                <li>Nghiên cứu, phát triển nội dung và data cho các sản phẩm web, app</li>
                <li>Sáng tạo nội dung để quảng cáo sản phẩm trên nền tảng số</li>
                <li><strong>Yêu cầu:</strong>&nbsp;Giỏi Tiếng Anh; đam mê viết lách; không yêu cầu kinh nghiệm</li>
                <li><strong>Hình thức làm việc:</strong>&nbsp;Full-time/Part-time tại Hà Nội</li>
              </ul>
            </div>
          </div>
          <a target="_blank" href="https://forms.gle/3TsHffPEkyCGQbmX8" rel="noreferrer">
            <button type="button" className="btn btn-apply">
              APPLY NOW
            </button>
          </a>
        </div>
      </div>
      <div className="career-item">
        <div className="career-item-card">
          <div className="d-flex">
            <div className="left-icon">
              <img src="/images/digital-marketing.png" alt="digital-marketing" className="career-icon" />
            </div>
            <div>
              <h3 className="career-title">Digital Marketing</h3>
              <ul>
                <li>Nghiên cứu và xây dựng kế hoạch Digital Marketing cho sản phẩm</li>
                <li>Triển khai kế hoạch nội dung đa nền tảng: website, social media, etc.</li>
                <li><strong>Yêu cầu:</strong>&nbsp;Biết Tiếng Anh; am hiểu mạng xã hội; không yêu cầu kinh nghiệm</li>
                <li><strong>Hình thức làm việc:</strong>&nbsp;Full-time/Intern tại Hà Nội</li>
              </ul>
            </div>
          </div>
          <a target="_blank" href="https://forms.gle/3TsHffPEkyCGQbmX8" rel="noreferrer">
            <button type="button" className="btn btn-apply">
              APPLY NOW
            </button>
          </a>
        </div>
      </div>
      <div className="career-item">
        <div className="career-item-card">
          <div className="d-flex">
            <div className="left-icon">
              <img src="/images/ui-ux.png" alt="ui-ux" className="career-icon" />
            </div>
            <div>
              <h3 className="career-title">UX/UI Designer</h3>
              <ul>
                <li>Phát triển quy trình thiết kế, xây dựng UI/UX cho sản phẩm App, Website, Web mobile</li>
                <li>Thiết kế thumbnail, banner, hình ảnh phục vụ cho các sản phẩm của công ty</li>
                <li><strong>Yêu cầu:</strong>&nbsp;Biết Tiếng Anh; thành thạo các công cụ thiết kế cơ bản; không yêu cầu kinh nghiệm</li>
                <li><strong>Hình thức làm việc:</strong>&nbsp;Full-time tại Hà Nội</li>
              </ul>
            </div>
          </div>
          <a target="_blank" href="https://forms.gle/3TsHffPEkyCGQbmX8" rel="noreferrer">
            <button type="button" className="btn btn-apply">
              APPLY NOW
            </button>
          </a>
        </div>
      </div>
      <div className="career-item">
        <div className="career-item-card">
          <div className="d-flex">
            <div className="left-icon">
              <img src="/images/Seo.png" alt="Seo" className="career-icon" />
            </div>
            <div>
              <h3 className="career-title">Chuyên viên SEO và ASO</h3>
              <ul>
                <li>Lập kế hoạch, triển khai SEO để đẩy mạnh thứ hạng và kéo traffic về sản phẩm web</li>
                <li>Lập kế hoạch, triển khai ASO để tăng thứ hạng tìm kiếm cho các app trên Google Play và App Store</li>
                <li><strong>Yêu cầu:</strong>&nbsp;Biết Tiếng Anh; có kiến thức cơ bản về SEO, ASO; không yêu cầu kinh nghiệm</li>
                <li><strong>Hình thức làm việc:</strong>&nbsp;Full-time tại Hà Nội</li>
              </ul>
            </div>
          </div>
          <a target="_blank" href="https://forms.gle/3TsHffPEkyCGQbmX8" rel="noreferrer">
            <button type="button" className="btn btn-apply">
              APPLY NOW
            </button>
          </a>
        </div>
      </div>
      <div className="career-item">
        <div className="career-item-card">
          <div className="d-flex">
            <div className="left-icon">
              <img src="/images/mobile.png" alt="mobile" className="career-icon" />
            </div>
            <div>
              <h3 className="career-title">Mobile Developer</h3>
              <ul>
                <li>Phát triển tính năng mới, duy trì và liên tục nâng cấp các sản phẩm app đang chạy</li>
                <li>Phát triển các dự án mobile app mới theo yêu cầu</li>
                <li><strong>Yêu cầu:</strong>&nbsp;Biết Tiếng Anh; có tư duy lập trình, kiến thức về thuật toán; không yêu cầu kinh nghiệm</li>
                <li><strong>Hình thức làm việc:</strong>&nbsp;Full-time tại Hà Nội</li>
              </ul>
            </div>
          </div>
          <a target="_blank" href="https://forms.gle/3TsHffPEkyCGQbmX8" rel="noreferrer">
            <button type="button" className="btn btn-apply">
              APPLY NOW
            </button>
          </a>
        </div>
      </div>
      <div className="career-item">
        <div className="career-item-card">
          <div className="d-flex">
            <div className="left-icon">
              <img src="/images/pm.png" alt="pm" className="career-icon" />
            </div>
            <div>
              <h3 className="career-title">Product Manager</h3>
              <ul>
                <li>Xây dựng và triển khai kế hoạch phát triển sản phẩm đến các bộ phận có liên quan</li>
                <li>Phân tích và nghiên cứu thị trường, đối thủ để xác định tầm nhìn và chiến lược phù hợp với sản phẩm</li>
                <li><strong>Yêu cầu:</strong>&nbsp;Biết Tiếng Anh; có khả năng lãnh đạo; không yêu cầu kinh nghiệm</li>
                <li><strong>Hình thức làm việc:</strong>&nbsp;Full-time tại Hà Nội</li>
              </ul>
            </div>
          </div>
          <a target="_blank" href="https://forms.gle/3TsHffPEkyCGQbmX8" rel="noreferrer">
            <button type="button" className="btn btn-apply">
              APPLY NOW
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}
