/* eslint-disable no-unused-vars */
import React, { Component } from 'react'

import { Link } from 'react-router-dom'

class DefaultHeader extends Component {
  render() {
    return (
      <div className="header container">
        <Link to="/">
          <img src="/images/logo.png" alt="logo" className="logo" />
        </Link>
      </div>
    )
  }
}

export default DefaultHeader
