/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import './index.scss'

// routes config
import routes from '../../constants/routes'
import DefaultFooter from '../../components/share/DefaultFooter'
import DefaultHeader from '../../components/share/DefaultHeader'

export default class DefaultLayout extends Component {
  render() {
    return (
      <div className="app">
        <DefaultHeader />
        <main className="main">
          <Switch>
            {routes.map((route, idx) => route.component ? (
              <Route
                key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                  <route.component {...props} />
                )}
              />
            )
              : (null))}
            {/* <Redirect from="/" to="/dashboard" /> */}
          </Switch>
        </main>
        <DefaultFooter />
      </div>
    )
  }
}
