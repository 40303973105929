import React, { Component } from 'react'

class DefaultFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <div className="footer">
        <div className="container footer-content">
          <div>
            {`© ${new Date().getFullYear()} Line Century Global JSC. All rights reserved`}
          </div>
          <div className="hn-love">
            Built with love in Hanoi
            <img src="/images/hn-logo.png" alt="hanoi logo" style={{ width: 24, height: 24, marginLeft: 8 }} />
          </div>
        </div>
      </div>
    )
  }
}

export default DefaultFooter
